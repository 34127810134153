<template>
      <v-footer
        :padless="true"
        fixed
      >
    <v-row class="ma-0">
      <v-col
        cols="12"
        sm="10"
        class="white--text text-center pa-0"
        id="footleft"
      >
          <b>HHEXHUB</b> application by Business IT Soulutions GmbH
      </v-col>
      <v-col
        cols="12"
        sm="2"
        class="white--text grey darken-4 text-center pa-0"
      >
          Version 0.3.0 (Alpha)
      </v-col>
    </v-row>
      </v-footer>    
  </template>
  <script>
  export default {
    name: 'FooterBar',
    data: () => ({
    }),
  }
</script>
<style>
#footleft{
  background-color: #B4152C;
}
</style>